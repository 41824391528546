<template>
	<div class="app flex-row align-items-center">
		<div class="container">
			<b-row class="justify-content-center">
				<b-col md="6">
					<div class="clearfix">
						<h1 class="float-left display-custom mr-4 primary-color">
							404
						</h1>
					</div>
					<div class="clearfix">
						<h4 class="pt-3">
							{{ translate('404_title') }}
						</h4>
						<p class="text-muted">
							{{ translate('404_page_not_found') }} <b-link :to="{name: getRedirectTo()}">
								{{ translate('go_home') }}
							</b-link>
						</p>
					</div>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import { Validations as messages } from '@/translations';

export default {
	messages,
	name: 'Page404',
	methods: {
		getRedirectTo() {
			const { redirectTo } = this.$route.query;
			return redirectTo || 'Main';
		},
	},
};
</script>
<style>
	.display-custom {
		font-size: 15rem
	}
</style>
